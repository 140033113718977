import * as ScreenOrientation from 'expo-screen-orientation'
import memoizeOne from 'memoize-one'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Dimensions, StyleSheet, View } from 'react-native'
import colors from './colors'
import fonts from './fonts'
import layout, { LayoutOptions } from './layout'

const ThemeContext = React.createContext<Theme | undefined>(undefined)

// const getOrientationType = currentOrientation => {
//   switch (currentOrientation) {
//     case ScreenOrientation.Orientation.LANDSCAPE_LEFT:
//     case ScreenOrientation.Orientation.LANDSCAPE_RIGHT: {
//       return 'landscape'
//     }
//     case ScreenOrientation.Orientation.PORTRAIT_DOWN:
//     case ScreenOrientation.Orientation.PORTRAIT_UP: {
//       return 'portrait'
//     }
//     default:
//       throw new Error('Unknown screen orientation')
//   }
// }

function getDeviceSizeType() {
  const window = Dimensions.get('window')
  const smallestSide =
    window.height > window.width ? window.width : window.height
  return smallestSide < 700 ? 'small' : 'large'
}

function getIsSmallDevice() {
  return getDeviceSizeType() === 'small'
}

export type Theme = ReturnType<typeof createTheme>

function createTheme(options: LayoutOptions) {
  return {
    isPresence: options.isSmallDevice,
    layout: layout(options),
    colors,
    fonts: fonts({ isSmallDevice: options.isSmallDevice })
  }
}

let theme = createTheme({
  window: Dimensions.get('window'),
  isSmallDevice: getIsSmallDevice()
})

// TODO: Get rid of this
export function getThemeSnapshot(): Theme {
  return theme
}

interface Props {
  children?: React.ReactNode
}

export function Provider({ children }: Props): JSX.Element {
  const [ready, setReady] = useState(false)

  const window = Dimensions.get('window')
  const isSmallDevice = getIsSmallDevice()

  // Update external theme
  theme = useMemo(() => {
    return createTheme({ window, isSmallDevice })
  }, [window.width, window.height, isSmallDevice])

  useEffect(() => {
    async function getOrientation() {
      // const orientationType = getOrientationType(
      //   await ScreenOrientation.getOrientationAsync()
      // )

      const isSmallDevice = getIsSmallDevice()

      // NOTE: Won't lock in LANDSCAPE on iPad until requireFullScreen handled by app.json in Expo SDK 37
      // await ScreenOrientation.lockPlatformAsync({
      //   screenOrientationArrayIOS: isSmallDevice
      //     ? [ScreenOrientation.Orientation.PORTRAIT_UP]
      //     : [ScreenOrientation.Orientation.LANDSCAPE_RIGHT],
      //   screenOrientationConstantAndroid: isSmallDevice ? 1 : 2
      // })

      setReady(true)
    }

    getOrientation()
  }, [])

  if (!ready) {
    return <View style={styles.loading} />
  }

  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
}

export const Consumer = ThemeContext.Consumer

const styles = StyleSheet.create({
  loading: {
    flex: 1,
    backgroundColor: 'black'
  }
})

export const ThemedStyleSheet = {
  create<T extends StyleSheet.NamedStyles<T>>(
    builder: (theme: Theme) => T
  ): (props: {
    children: (styles: T, theme: Theme) => React.ReactNode
  }) => JSX.Element {
    const memoizedBuilder = memoizeOne((currentTheme: Theme) =>
      StyleSheet.create<T>(builder(currentTheme))
    )

    return ({ children }) => (
      <Consumer>
        {(currentTheme) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          children(memoizedBuilder(currentTheme!), currentTheme!)
        }
      </Consumer>
    )
  },

  createBuilder<T extends StyleSheet.NamedStyles<T>>(
    builder: (theme: Theme) => T
  ): (theme: Theme) => T {
    const memoizedBuilder = memoizeOne((currentTheme: Theme) =>
      StyleSheet.create<T>(builder(currentTheme))
    )

    return memoizedBuilder
  }
}

export function useTheme(): Theme {
  const value = useContext(ThemeContext)

  if (!value) throw new Error('Missing ThemeProvider')

  return value
}

export function useThemedStyles<T extends StyleSheet.NamedStyles<T>>(
  builder: (theme: Theme) => T
): T {
  const theme = useTheme()

  return builder(theme)
}
