interface FontOptions {
  isSmallDevice: boolean
}

export default ({ isSmallDevice }: FontOptions) =>
  ({
    defaultFontFamily: isSmallDevice ? 'graphik' : 'Futura',
    fontSizeJumbo: 30,
    fontSizeLarge: 18,
    fontSizeMedium: 16,
    fontSizeBody: 14,
    fontSizeSmall: 12,
    fontSizeXSmall: 10
  } as const)
