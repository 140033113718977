import { View } from 'react-native'
import React, { useMemo } from 'react'

interface Props {
  size?: number
  horizontal?: boolean
}

export default function Spacer({
  size,
  horizontal = false
}: Props): JSX.Element {
  const style = useMemo(
    () => ({
      minWidth: horizontal ? size : 0,
      maxWidth: horizontal ? size : 0,
      minHeight: horizontal ? 0 : size,
      maxHeight: horizontal ? 0 : size,
      flex: size == undefined ? 1 : 0
    }),
    [size, horizontal]
  )

  return <View style={style} />
}
