export interface LayoutOptions {
  isSmallDevice: boolean
  window: {
    width: number
  }
}

export default ({ isSmallDevice, window }: LayoutOptions) =>
  ({
    isSmallDevice,
    window,
    header: {
      width: 1024, // windowWidth,
      height: 60,
      top: 0,
      left: 0
    },
    switcher: {
      width: isSmallDevice ? window.width - 10 : 500
    },
    tab: {
      width: 1024 / 5,
      height: 56,
      top: 712,
      left: 0
    },
    tab3: {
      width: 1024 / 3,
      height: 56,
      top: 712,
      left: 0
    },
    tab4: {
      width: 1024 / 4,
      height: 56,
      top: 712,
      left: 0
    },
    tab5: {
      width: 1024 / 5,
      height: 56,
      top: 712,
      left: 0
    },
    signInButton: {
      width: isSmallDevice ? 200 : 250,
      height: 44,
      margin: 20
    },
    screenFull: {
      height: 583,
      width: 944
    },
    carousel: {
      width: 757,
      height: 573
    },
    mainPanel: {
      width: 623,
      height: 573
    },
    sidePanel: {
      width: 286,
      height: 573
    },
    icon: {
      jumbo: { size: 60 },
      large: { size: 40 },
      medium: { size: 30 },
      small: { size: 20 }
    },
    borderRadius: {
      tight: 5,
      medium: 10
    }
  } as const)
