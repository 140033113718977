import React, { Component, ReactNode } from 'react'
import { Animated, Image, StyleSheet, View, ViewStyle } from 'react-native'

interface Props {
  style: ViewStyle
  children: ReactNode
  thumbnailUrl: string
}

export default class PlaylistPreview extends Component<Props> {
  render() {
    const { style, children, thumbnailUrl } = this.props

    return (
      <View style={style}>
        <Image
          style={[StyleSheet.absoluteFill, styles.image]}
          source={{ uri: thumbnailUrl }}
        />
        <View style={styles.content}>{children}</View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  content: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 5,
    ...StyleSheet.absoluteFillObject
  },
  image: {
    resizeMode: 'cover'
  }
})
