export default {
  dartCarbonGrey: '#404040',
  dartCardinalRed: '#d12f2e',
  dartChartreuse: '#80ff00',
  dartLightPurple: '#ccc8d9',
  dartPurple: '#a39eb8',
  dartTransparentLightGrey: 'rgba(0, 0, 0, 0.6)',
  dartTransparentLightWhite: 'rgba(255, 255, 255, 0.5)',
  dartTransparentMediumWhite: 'rgba(255, 255, 255, 0.6)',
  dartTransparentStrongGrey: 'rgba(0, 0, 0, 0.5)',
  dartTransparentStrongWhite: 'rgba(255, 255, 255, 0.7)',
  dartTransparentVeryLightWhite: 'rgba(255, 255, 255, 0.3)',
  dartTransparentVeryStrongGrey: 'rgba(0, 0, 0, 0.7)',
  errorBackground: 'red',
  errorText: '#fff',
  facebookButton: '#3b5998',
  googleButton: '#dd4b39',
  get noticeBackground(): string {
    return this.tintColor
  },
  noticeText: '#fff',
  systemGray: 'rgb(142, 142, 147)',
  systemGray2: 'rgb(99, 99, 102)',
  systemGray3: 'rgb(72, 72, 74)',
  systemGray4: 'rgb(58, 58, 60)',
  systemGray5: 'rgb(44, 44, 46)',
  systemGray6: 'rgb(28, 28, 30)',
  tabBar: '#fefefe',
  tabIconDefault: 'rgb(58, 58, 60)',
  get tabIconSelected(): string {
    return this.tintColor
  },
  taxiYellow: '#f7b731',
  tintColor: 'rgb(142, 142, 147)',
  warningBackground: '#EAEB5E',
  warningText: '#666804'
}
