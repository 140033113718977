import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import {
  Animated,
  Easing,
  TouchableWithoutFeedback,
  ViewStyle
} from 'react-native'

interface Props {
  onPress: () => void
  activeOpacity?: number
  activeScale?: number
  style?: ViewStyle
  children: ReactNode
}

const getValue = (pressed: boolean) => {
  return pressed ? 1 : 0
}

export default memo(function FadeButton({
  onPress,
  activeOpacity = 0.5,
  activeScale = 0.98,
  style,
  children
}: Props): JSX.Element {
  const [pressed, setPressed] = useState(false)
  const value = useRef(new Animated.Value(getValue(false))).current

  useEffect(() => {
    Animated.timing(value, {
      duration: pressed ? 100 : 50,
      toValue: getValue(pressed),
      easing: Easing.out(Easing.quad),
      useNativeDriver: true
    }).start()
  }, [pressed])

  const handlePressIn = useCallback(() => setPressed(true), [])

  const handlePressOut = useCallback(() => setPressed(false), [])

  const styles = useMemo(
    () => [
      style,
      {
        zIndex: pressed ? 1 : 0,
        opacity: value.interpolate({
          inputRange: [0, 1],
          outputRange: [1, activeOpacity]
        }),
        transform: [
          {
            scale: value.interpolate({
              inputRange: [0, 1],
              outputRange: [1, activeScale]
            })
          }
        ]
      }
    ],
    [style, activeScale, activeOpacity]
  )

  return (
    <TouchableWithoutFeedback
      onPress={onPress}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
    >
      <Animated.View style={styles}>{children}</Animated.View>
    </TouchableWithoutFeedback>
  )
})
