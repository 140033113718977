import React, { PureComponent, useMemo } from 'react'
import {
  FlatList,
  View,
  Text,
  Dimensions,
  Platform,
  ViewToken,
  ListRenderItem
} from 'react-native'
import { BlurView } from 'expo-blur'

import { ThemedStyleSheet } from '../theme'

import PlaylistPreview from './PlaylistPreview'
import Spacer from './Spacer'
import FadeButton from './FadeButton'
import { PlaylistGroup } from '../selectors/types'

const extractKey = ({ id }: { id: string }) => id

const CellRendererComponent = ({ style, ...restProps }: any) => {
  const cellStyle = useMemo(() => [style, { flex: 1 }], [style])
  return <View {...restProps} style={cellStyle} />
}

interface ItemProps {
  title: string
  onPress: () => void
  thumbnailUrl: string
}

const ItemComponent = ({ title, onPress, thumbnailUrl }: ItemProps) => {
  return (
    <ThemedStyles>
      {(styles) => (
        <FadeButton style={styles.item} onPress={onPress}>
          <PlaylistPreview
            style={styles.playlistPreview}
            thumbnailUrl={thumbnailUrl}
          >
            <BlurView
              tint="dark"
              intensity={75}
              style={styles.blurredTitleBackground}
            >
              <Text style={styles.title} adjustsFontSizeToFit numberOfLines={2}>
                {title}
              </Text>
            </BlurView>
          </PlaylistPreview>
        </FadeButton>
      )}
    </ThemedStyles>
  )
}

interface Props {
  data: PlaylistGroup[]
  itemMargin: number
  onPressItem: (id: string) => void
  activeItemIndex: number
  activePageIndex: number
  onItemViewable: (index: number | null) => void
}

interface State {
  viewablePageIndex: number | null
}

export default class PlaylistCarousel extends PureComponent<Props, State> {
  static defaultProps = {
    itemMargin: 5
  }

  state = {
    viewablePageIndex: 0
  }

  renderItem: ListRenderItem<PlaylistGroup> = ({ index, item: { items } }) => {
    const { onPressItem, itemMargin, activeItemIndex } = this.props
    const { viewablePageIndex } = this.state

    // console.log(
    //   index === viewablePageIndex,
    //   index,
    //   viewablePageIndex,
    //   activeItemIndex
    // )

    // We want to get the device width on render, in case the device is rotated
    const { width } = Dimensions.get('window')

    return (
      <ThemedStyles>
        {(styles) => (
          <View style={[styles.pageContainer, { width }]}>
            <View style={styles.row}>
              <ItemComponent
                onPress={() => onPressItem(items[0].id)}
                title={items[0].title}
                thumbnailUrl={items[0].thumbnailUrl}
              />
              <Spacer horizontal size={itemMargin} />
              <ItemComponent
                onPress={() => onPressItem(items[1].id)}
                title={items[1].title}
                thumbnailUrl={items[1].thumbnailUrl}
              />
            </View>
            <Spacer size={itemMargin} />
            <View style={styles.row}>
              <ItemComponent
                onPress={() => onPressItem(items[2].id)}
                title={items[2].title}
                thumbnailUrl={items[2].thumbnailUrl}
              />
              <Spacer horizontal size={itemMargin} />
              <ItemComponent
                onPress={() => onPressItem(items[3].id)}
                title={items[3].title}
                thumbnailUrl={items[3].thumbnailUrl}
              />
            </View>
          </View>
        )}
      </ThemedStyles>
    )
  }

  // TODO: investigate Media tab rendering for Android
  viewabilityConfig = {
    viewAreaCoveragePercentThreshold: Platform.OS === 'ios' ? 100 : 80
  }

  handleItemViewable = ({ viewableItems }: { viewableItems: ViewToken[] }) => {
    const viewableIndices = viewableItems
      .filter((item) => item.isViewable)
      .map((item) => item.index)

    if (viewableIndices.length > 0) {
      this.setState({ viewablePageIndex: viewableIndices[0] })

      this.props.onItemViewable(viewableIndices[0])
    }
  }

  componentDidMount() {
    this.updateScrollState()
  }

  componentDidUpdate() {
    this.updateScrollState()
  }

  updateScrollState() {
    const { viewablePageIndex } = this.state
    const { activePageIndex, data } = this.props

    // console.log(
    //   'PlaylistCarousel updateScrollState() // Active:',
    //   activePageIndex,
    //   'Data length:',
    //   data.length,
    //   'Viewable',
    //   viewablePageIndex
    // )

    if (activePageIndex != null && activePageIndex !== viewablePageIndex) {
      this.setState({ viewablePageIndex: activePageIndex })

      // console.log(
      //   'PlaylistCarousel scrolling to index! // Active:',
      //   activePageIndex,
      //   'Data length:',
      //   data.length,
      //   'Viewable',
      //   viewablePageIndex
      // )

      // Guard against scrollToIndex out of range
      if (activePageIndex >= data.length) return

      this.list.current?.scrollToIndex({
        index: activePageIndex
      })
    }
  }

  getItemLayout: (
    data: Array<PlaylistGroup> | null | undefined,
    index: number
  ) => { length: number; offset: number; index: number } = (data, index) => {
    const { width } = Dimensions.get('window')

    return { length: width, offset: width * index, index }
  }

  list = React.createRef<FlatList>()

  render() {
    const { activeItemIndex, data } = this.props
    const { viewablePageIndex } = this.state

    return (
      <ThemedStyles>
        {(styles) => (
          <FlatList
            ref={this.list}
            // initialScrollIndex={activeItemIndex}
            viewabilityConfig={this.viewabilityConfig}
            horizontal
            pagingEnabled
            style={styles.container}
            data={data}
            renderItem={this.renderItem}
            keyExtractor={extractKey}
            onViewableItemsChanged={this.handleItemViewable}
            extraData={`${activeItemIndex}:${viewablePageIndex}`}
            getItemLayout={this.getItemLayout}
            CellRendererComponent={CellRendererComponent}
          />
        )}
      </ThemedStyles>
    )
  }
}

const ThemedStyles = ThemedStyleSheet.create(({ colors, layout }) => ({
  container: {
    flex: 1
  },
  pageContainer: {
    padding: 20,
    justifyContent: 'center',
    alignContent: 'center'
  },
  row: { flex: 1, flexDirection: 'row' },
  title: {
    fontFamily: 'Futura',
    fontSize: layout.isSmallDevice ? 14 : 24,
    color: 'white',
    textAlign: 'center'
  },
  activeTitleWithShadow: {
    fontFamily: 'Futura',
    fontSize: layout.isSmallDevice ? 14 : 24,
    color: 'white',
    shadowColor: colors.systemGray6,
    shadowOffset: { height: 1, width: 1 },
    shadowOpacity: 1,
    shadowRadius: 1,
    textAlign: 'center'
  },
  item: {
    flex: 1,
    backgroundColor: colors.systemGray6,
    borderRadius: 10,
    alignItems: 'stretch',
    overflow: 'hidden'
  },
  blurredTitleBackground: {
    overflow: 'hidden',
    borderRadius: 12,
    paddingHorizontal: 10
  },
  activeBlurredTitleBackgroundWithShadow: {
    overflow: 'hidden',
    borderRadius: 12,
    paddingHorizontal: 10,
    shadowColor: 'white',
    shadowOffset: { height: 1, width: 1 },
    shadowOpacity: 1,
    shadowRadius: 5
  },
  playlistPreview: {
    flex: 1
  }
}))
