import { Ionicons } from '@expo/vector-icons'
import { StatusBar } from 'expo-status-bar'
import * as WebBrowser from 'expo-web-browser'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Animated,
  Easing,
  Image,
  Pressable,
  StyleSheet,
  Text,
  View
} from 'react-native'
import { SocketIOProvider, useLastMessage, useSocket } from 'use-socketio'
import PlaylistCarousel from './components/PlaylistCarousel'
import { PlaylistGroup } from './selectors/types'
import * as Theme from './theme'
import { decode } from './utils/queryString'

const dartUrl = 'http://dart.art/disco'
const privacyUrl = 'http://dart.art/disco-privacy'
const termsUrl = 'http://dart.art/disco-terms'

const STATUS_VALUE = {
  WAITING_FOR_CAROUSEL: 'WAITING_FOR_CAROUSEL',
  READY: 'READY'
} as const

type STATUS = 'WAITING_FOR_CAROUSEL' | 'READY'

interface Props {
  encryptedParams: string
  remoteServiceUrl: string | undefined
}

function App({ encryptedParams, remoteServiceUrl }: Props) {
  const { socket } = useSocket()
  const [carouselStatus, setCarouselStatus] = useState<STATUS>(
    'WAITING_FOR_CAROUSEL'
  )
  const [activePageIndex, setActivePageIndex] = useState(2)
  const opacityValue = useRef(new Animated.Value(0))

  useEffect(() => {
    if (!encryptedParams) return

    socket.emit('view', encryptedParams)
  }, [encryptedParams, socket])

  const sendNavigationCommand = useCallback(
    (playlistId: string) => {
      if (!encryptedParams) {
        console.log('Missing encrypted params')
        return
      }

      socket.emit('navigate', encryptedParams, playlistId)
    },
    [encryptedParams, socket]
  )

  const hostData = useLastMessage('view').data as
    | { playlists: PlaylistGroup[] }
    | undefined

  useEffect(() => {
    if (hostData && carouselStatus === 'READY') {
      Animated.timing(opacityValue.current, {
        duration: 500,
        toValue: 1,
        easing: Easing.in(Easing.quad),
        useNativeDriver: true
      }).start()
    }
  }, [hostData, carouselStatus])

  const remoteServiceStatus = remoteServiceUrl
    ? '[remoteService OK]'
    : '[missing remoteService]'

  const encryptedParamsStatus = encryptedParams
    ? '[viewerToken OK]'
    : '[missing viewerToken]'

  const status = [remoteServiceStatus, encryptedParamsStatus].join(' ')

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Pressable onPress={async () => WebBrowser.openBrowserAsync(dartUrl)}>
          <Image
            source={require('./assets/DART.png')}
            resizeMode="contain"
            style={styles.logo}
          />
        </Pressable>
        <Text style={styles.title}>Disco Remote</Text>
      </View>
      <View style={styles.subheader}>
        <Ionicons name="caret-back-circle-outline" size={20} color="black" />
        <Text style={styles.subtitle}>select channel</Text>
        <Ionicons name="caret-forward-circle-outline" size={20} color="black" />
      </View>
      {hostData && (
        <Animated.View
          style={{ flex: 1, maxWidth: '100%', opacity: opacityValue.current }}
        >
          <PlaylistCarousel
            data={hostData.playlists}
            activeItemIndex={0}
            activePageIndex={activePageIndex}
            onPressItem={sendNavigationCommand}
            onItemViewable={(key) => {
              if (
                carouselStatus === STATUS_VALUE.WAITING_FOR_CAROUSEL &&
                activePageIndex === key
              ) {
                setCarouselStatus('READY')
                setActivePageIndex(key)
              } else if (carouselStatus === STATUS_VALUE.READY) {
                setActivePageIndex(key!)
              }
            }}
          />
        </Animated.View>
      )}
      <View style={styles.footer}>
        <Text style={styles.connectedStatus}>{status}</Text>
        <View style={styles.legal}>
          <Pressable
            onPress={async () => WebBrowser.openBrowserAsync(privacyUrl)}
          >
            <Text
              style={[styles.footerText, { textDecorationLine: 'underline' }]}
            >
              Privacy
            </Text>
          </Pressable>
          <Text style={styles.footerText}> | </Text>
          <Pressable
            onPress={async () => WebBrowser.openBrowserAsync(termsUrl)}
          >
            <Text
              style={[styles.footerText, { textDecorationLine: 'underline' }]}
            >
              Terms
            </Text>
          </Pressable>
        </View>
      </View>
      <StatusBar style="auto" />
    </View>
  )
}

export default function AppWithSocket(): JSX.Element {
  const queryParams = useMemo(
    () => decode(window.location.search.substring(1)),
    [window.location]
  )

  return (
    <SocketIOProvider url={queryParams.h}>
      <Theme.Provider>
        <App remoteServiceUrl={queryParams.h} encryptedParams={queryParams.p} />
      </Theme.Provider>
    </SocketIOProvider>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 25
  },
  header: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingVertical: 5
  },
  subheader: {
    backgroundColor: '#E6E6FA',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 5,
    borderRadius: 5
  },
  footer: {
    flexDirection: 'column',
    alignItems: 'center',
    bottom: 0
  },
  legal: {
    backgroundColor: '#f9f9f9',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
    borderRadius: 5
  },
  logo: {
    height: 30,
    width: 30
  },
  title: { fontSize: 18, color: 'black', margin: 10 },
  subtitle: { fontSize: 14, color: 'black', margin: 10, paddingHorizontal: 5 },
  footerText: { fontSize: 8, color: 'blue' },
  connectedStatus: {
    fontSize: 10,
    color: '#080808',
    textAlignVertical: 'bottom',
    margin: 5
  }
})
